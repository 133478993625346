import { useNavigate } from 'react-router-dom';
import './ItemInfo.scss';
import { collections } from '../../../assets/homepage';
import { MintActionType, useMintDispatch } from '../../../context/mint/MintContext';

import IconGem from '@assets/_gemz/icons8-diamond-90.png';
import IconShare from '@assets/_gemz/icons8-share-100.png';
import { useAppState } from '@context/AppContext';
import { GemzItem } from '@common/GemzItem';

type DebugData = {
  currentSlide: number;
  viewerReady: boolean;
  gameLoadingState: string;
  disableScroll: boolean;
};

export type ItemInfoProps = {
  debug?: DebugData;
  gemz?: GemzItem;
  enabled: boolean;
  bottomInfoEnabled: boolean;
  type: 'live' | 'register';
  onClick: () => void;
};

export function ItemInfo({ enabled, bottomInfoEnabled, type, onClick, gemz, debug }: ItemInfoProps) {
  // console.warn('>>> ItemInfo - gemz:', gemz);
  const appState = useAppState();

  // todo carles/jb: this data needs be inserted on GemzItem. we need the handle string and the url to author's twitter.
  const str = gemz.username.toString().replace(/\s/g, '');
  const authorTwitterHandle = `@${str}`;
  const authorTwitterUrl = 'https://twitter.com';

  const description = gemz.gemname
    .replace(/(\r\n|\n|\r)/gm, '')
    .split('</p>')[0]
    .trim();

  let bottomClassName = '';
  if (useAppState().showGame) {
    bottomClassName = bottomInfoEnabled ? 'enabled' : 'disabled';
  }

  const share = async () => {
    const base = 'https://main-site-p-gem-dev.fodaeh.net/';
    const url = `${base}/${gemz.gemname.replace(/ /g, '_').toLocaleLowerCase().trim()}`;

    const shareData = {
      title: 'Gemz',
      text: 'Look at my new story!',
      url,
    };

    try {
      await navigator.share(shareData);
      console.warn('Gemz story shared successfully');
    } catch (err) {
      console.warn(`Gemz story share Error: ${err}`);
    }
  };

  const text = `
  currentSlide ${debug.currentSlide}
  viewerReady ${debug.viewerReady}
  showGame ${appState.showGame}
  ${debug.gameLoadingState}`;
  // disableScroll ${debug.disableScroll}`;

  const debugLog = text.split('\n').map((str, index) => <div key={index}>{str}</div>);

  return (
    enabled && (
      <div className={`item-info`}>
        {/* <div className="line-mid-screen" /> */}

        <div className={`item-info-bottom ${bottomClassName}`}>
          <a href={authorTwitterUrl} className="author">
            {authorTwitterHandle}
          </a>
          <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>

        <div className="sidebar">
          <div
            className="sidebar-item author"
            onClick={() => {
              console.warn('>>> tap on author');
            }}
          >
            {/* <div className="sidebar-item-icon" style={{ backgroundImage: `url(${collectionMedia.avatar})` }} /> */}
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${gemz.profile_image_url})` }} />
            <div className="sidebar-item-label">{'198\nETH'}</div>
          </div>

          <div
            className="sidebar-item gem"
            onClick={() => {
              console.warn('>>> tap on gem');
            }}
          >
            {/* <div className="sidebar-item-icon" style={{ backgroundImage: `url(${IconGem})` }} /> */}
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${gemz.gemimage})` }} />
            <div className="sidebar-item-label">{'0.04\nETH'}</div>
          </div>

          <div
            className="sidebar-item share"
            onClick={() => {
              console.warn('>>> tap on share');
              share();
            }}
          >
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${IconShare})` }} />
            <div className="sidebar-item-label">Share</div>
          </div>

          <div className="sidebar-item debug">{debugLog}</div>
        </div>
      </div>
    )
  );
}

export default ItemInfo;
