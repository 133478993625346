import './BottomNavBar.scss';

import IconHome from '@assets/_gemz/icons8-home-100.png';
import IconProfile from '@assets/_gemz/icons8-profile-100.png';
import IconCreate from '@assets/_gemz/icons8-create-100.png';
import IconInbox from '@assets/_gemz/icons8-inbox-100.png';
import IconAirdrop from '@assets/_gemz/icons8-airdrop-100.png';

import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useGemzState } from '@context/gemz/GemzContext';
import { getPagePath } from '@common/GemzItem';

export type BottomNavBarProps = {};

export function BottomNavBar({}: BottomNavBarProps) {
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const gemzState = useGemzState();

  const navigate = useNavigate();

  const navigateToHome = async () => {
    console.warn('>>> navigateToHome', appState.navbarEnabled);
    if (!appState.navbarEnabled) {
      return;
    }
    // console.warn('>>> Home click');
    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: false,
    });

    appDispatch({
      type: AppActionType.UpdateIsAiForm,
      isAiForm: false,
    });

    appDispatch({
      type: AppActionType.UpdateIsAiGenMode,
      isAiGenMode: false,
    });

    // const otherPages = ['profile', 'create', 'notification', 'explore'];
    // if (gemzState.feed?.length && otherPages.some((page) => location.href.includes(page))) {
    //   const newGemRoute = getPagePath(gemzState.feed[0].gemname);
    //   navigate(newGemRoute);
    // }

    navigate('/');
  };

  const navigateToCreate = async () => {
    if (!appState.navbarEnabled) {
      return;
    }

    appDispatch({
      type: AppActionType.UpdateIsAiForm,
      isAiForm: true,
    });

    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: true,
    });

    // console.warn('>>> Create click');
    if (appState.initialSearchParams.size) {
      navigate(`/create?${appState.initialSearchParams.toString()}`);
    } else {
      navigate('/create');
    }
  };

  const navigateToInbox = async () => {
    {
      if (!appState.navbarEnabled) {
        return;
      }
      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: false,
      });
      navigate('/notifications');
      // console.warn('>>> Inbox click');
    }
  };

  const navigateToProfile = async () => {
    {
      if (!appState.navbarEnabled) {
        return;
      }

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: false,
      });

      navigate('/profile');
      // console.warn('>>> Inbox click');
    }
  };

  return (
    <div className="bottom-navbar">
      <div className="navbar-items">
        <div className="navbar-item home" onClick={async () => navigateToHome()}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconHome})` }} />
          <div className="navbar-item-label">Home</div>
        </div>

        <div className="navbar-item inbox" onClick={() => navigateToInbox()}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconInbox})` }} />
          <div className="navbar-item-label">Inbox</div>
        </div>

        <div className="navbar-item create" onClick={() => navigateToCreate()}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconCreate})` }} />
          <div className="navbar-item-label">Create</div>
        </div>

        <div className="navbar-item airdrop" onClick={() => {}}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconAirdrop})` }} />
          <div className="navbar-item-label">Airdrop</div>
        </div>

        <div className="navbar-item profile" onClick={() => navigateToProfile()}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconProfile})` }} />
          <div className="navbar-item-label">Profile</div>
        </div>
      </div>
    </div>
  );
}

export default BottomNavBar;
