import './BottomCreateBar.scss';

import IconBack from '@assets/_gemz/profile/icons8-back-100.png';
import IconRestart from '@assets/_gemz/create/icons8-restart-100.png';
import IconEdit from '@assets/_gemz/create/icons8-edit-90.png';

import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useGemzState } from '@context/gemz/GemzContext';
import { mainSuite } from '@services/ServiceFactory';
import { useEffect, useState } from 'react';

let hasPublishedOnce = false;

export type BottomCreateBarProps = {};

export function BottomCreateBar({}: BottomCreateBarProps) {
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const gemzState = useGemzState();

  const navigate = useNavigate();

  const [aiProgress, setAiProgress] = useState(0);
  const [aiComplete, setAiComplete] = useState(false);

  // Add viewer callback
  useEffect(() => {
    const onUpdateAiProgress = (progress: number) => {
      console.warn('>>> onUpdateAiProgress', progress);

      setAiProgress(progress);
    };

    const onCompleteAiProgress = () => {
      console.warn('>>> onCompleteAiProgress');
      setAiComplete(true);
    };

    mainSuite.navbarService.on('UPDATE_AI_PROGRESS', onUpdateAiProgress);
    mainSuite.navbarService.on('GENERATE_AI_STORY_COMPLETE', onCompleteAiProgress);

    return () => {
      mainSuite.navbarService.off('UPDATE_AI_PROGRESS', onUpdateAiProgress);
      mainSuite.navbarService.off('GENERATE_AI_STORY_COMPLETE', onCompleteAiProgress);
    };
  }, []);

  const back = async () => {
    mainSuite.navbarService.sendClientEvent('CHANGE_GEMZ_PAGE', 'view');

    appDispatch({
      type: AppActionType.UpdateIsAiGenMode,
      isAiGenMode: false,
    });

    appDispatch({
      type: AppActionType.ToggleEnableNavbar,
      navbarEnabled: true,
    });

    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: false,
    });

    navigate('/');
  };

  const restart = async () => {
    // send a message to the game in order to restart it.
    mainSuite.navbarService.sendClientEvent('RESTART_GAME');
  };

  const edit = async () => {
    // for now, lets just re-trigger the ai form, but this will need to be quite more complicated
    // since this feature is intended for the user to adjust tweaks on the fly
    mainSuite.navbarService.sendClientEvent('GOTO_GAME_CREATOR');
  };

  const publish = async () => {
    // for now, send a message to the game in order to buy the gem right away
    if (hasPublishedOnce) {
      console.error('>>> You have already tapped on publish button for this story');
      return;
    }

    mainSuite.navbarService.sendClientEvent('PUBLISH_GAME');
    hasPublishedOnce = true;
  };

  return (
    <div className="ai-generation-ui">
      <div className="topbar">
        <div className="topbar-items">
          <div className="topbar-item back" onClick={async () => back()}>
            <div className="topbar-item-icon" style={{ backgroundImage: `url(${IconBack})` }} />
          </div>
        </div>
      </div>

      <div className="bottombar">
        <div className="bottombar-items">
          <div className="bottombar-item restart" onClick={async () => restart()}>
            <div className="bottombar-item-icon" style={{ backgroundImage: `url(${IconRestart})` }} />
            <div className="bottombar-item-label">Restart</div>
          </div>

          <div className="bottombar-item edit" onClick={async () => edit()}>
            <div className="bottombar-item-icon" style={{ backgroundImage: `url(${IconEdit})` }} />
            <div className="bottombar-item-label">Edit</div>
          </div>

          <div className="bottombar-separator" />

          <div className="bottombar-item ai-progress">
            {!aiComplete && (
              <div className="progress-title">
                Generating game
                <div className="progress-subtitle">You can still play while AI is generating content.</div>
              </div>
            )}

            {!aiComplete && <div className="progress-bar" style={{ width: aiProgress * 2 }} />}

            {aiComplete && (
              <div className="bottombar-item publish" onClick={async () => publish()}>
                <div className="bottombar-item-label">Publish</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomCreateBar;
