import './AiCreateForm.scss';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import { TransitioningSection } from '@components/transitioning-section';
import { mainSuite } from '@services/ServiceFactory';

export const AiCreateForm = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const appState = useAppState();

  const inputRefs = useRef([]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateShowOnboard,
      showOnboard: false,
    });

    // send a message to game that we enabled the ai form
    mainSuite.navbarService.sendClientEvent('AI_FORM', true);
  }, []);

  const generateAiGame = async () => {
    {
      // generate instructions data from inputs content
      const instructions = {};
      inputRefs.current.forEach((ref, index) => {
        const input = ref as HTMLInputElement;
        instructions[input.id] = input.value || input.placeholder;
      });

      console.warn('>>> website - generate ai game instructions:', instructions);

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: true,
      });

      appDispatch({
        type: AppActionType.UpdateIsAiForm,
        isAiForm: false,
      });

      // send a message to game that we are done with ai form and we are staring ai generation mode
      console.warn('>>> sending AI_GENERATE_GAME event to game...');
      mainSuite.navbarService.sendClientEvent('AI_FORM', false);
      mainSuite.navbarService.sendClientEvent('AI_GENERATE_GAME', instructions);
    }
  };

  console.warn('>>> AiCreateForm isAiGenMode', appState.isAiGenMode, 'isAiForm', appState.isAiForm, 'showGame', appState.showGame);
  let loaded = true;
  let disabled = false;

  return (
    <div className="ai-form">
      <div className="title">Let's create your game!</div>

      <div className="inputG">
        <p>Who?</p>
        <input ref={(ref) => (inputRefs.current[0] = ref)} id={'who'} placeholder={'A corrupt cop, a lawful cop and a suspect'} />
      </div>
      <div className="inputG">
        <p>Where?</p>
        <input ref={(ref) => (inputRefs.current[1] = ref)} id={'where'} placeholder={'Police headquarters'} />
      </div>

      <div className="inputG">
        <p>What's the goal?</p>
        <input ref={(ref) => (inputRefs.current[2] = ref)} id={'what'} placeholder={'Make the suspect confess'} />
      </div>

      <div className="inputG">
        <p>Tone?</p>
        <input ref={(ref) => (inputRefs.current[3] = ref)} id={'tone'} placeholder={'Conflicting, dark humour'} />
      </div>

      <div className="advanced-options">
        <p>Advanced Options</p>
      </div>

      <button className="create-button" disabled={disabled} onClick={() => generateAiGame()}>
        Generate
        {/* {isLoading ? 'Loading...' : outcome ? outcome : 'Create!'} */}
      </button>

      {!loaded && <TransitioningSection fixed={true} />}
    </div>
  );
};
